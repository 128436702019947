<template>
  <v-app class="transparent">
    <v-container
      id="user-profile"
      fluid
      tag="section">
      <base-v-component heading="Create Subscriber"/>
      <base-material-card
        icon="mdi-account-multiple-check"
        title="Create Subscriber"
        class="px-5 py-3">
        <v-form
          ref="form"
          on-submit="return false;"
          @submit="submit"
        >
          <v-container class="py-0">
            <VValidationErrors :validationErrors="validationErrors"></VValidationErrors>
            <v-row>
              <v-col
                cols="12"
                md="6"
                v-if="showFirstName"
              >
                <v-text-field
                  v-model="subscriber.first_name"
                  :rules="rules.firstName"
                  label="First name"
                  class="purple-input"
                  required
                  outlined
                />
              </v-col>
              <v-col
                cols="12"
                md="6"
                v-if="showLastName"
              >
                <v-text-field
                  v-model="subscriber.last_name"
                  :rules="rules.lastName"
                  label="Last name"
                  class="purple-input"
                  required
                  outlined
                />
              </v-col>

              <v-col
                cols="12"
                md="6"
                v-if="showEmail"
              >
                <v-text-field
                  v-model="subscriber.email"
                  :rules="rules.email"
                  label="Email Address"
                  class="purple-input"
                  outlined
                />
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <template>
                  <vue-tel-input
                    required
                    validCharactersOnly
                    mode="international"
                    v-model="subscriber.mobile"
                    :ignored-countries="['IL']"
                    :dynamic-placeholder="true"
                    :preferred-countries="['jo', 'sa', 'eg']"
                    @country-changed="countrySelected">
                    <template v-slot:arrow-icon>
                      <strong>+{{ subscriber.countryCode }}</strong>
                    </template>
                  </vue-tel-input>
                </template>
              </v-col>
              <v-col cols="12" sm="6" md="6" v-if="showCountryId">
                <v-autocomplete
                  v-model="subscriber.country_id"
                  :items="countries"
                  item-text="name_ar"
                  item-value="id"
                  label="Country"
                  outlined
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6" md="6" v-if="showBranchId">
                <v-autocomplete
                  v-model="subscriber.branch_id"
                  :items="branches"
                  item-text="name"
                  item-value="id"
                  label="Branch"
                  outlined
                ></v-autocomplete>
              </v-col>
              <v-col
                v-if="showDateOfBirth"
                cols="12"
                sm="6"
                md="6">
                <v-menu
                  v-model="menu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      slot="on"
                      v-model="subscriber.date_of_birth"
                      label="Date Of Birth"
                      locale="en-in"
                      class="purple-input"
                      append-icon="mdi-calendar-range"
                      readonly
                      v-on="on"
                      outlined
                      :rules="rules.dateOfBirth"
                    />
                  </template>
                  <v-date-picker
                    v-model="subscriber.date_of_birth"
                    year-icon="mdi-calendar-blank"
                    prev-icon="mdi-skip-previous"
                    next-icon="mdi-skip-next"
                    @input="menu = false"
                    :max="maxDate"
                    :show-current="maxDate"
                  />
                </v-menu>
              </v-col>
              <v-col
                cols="12"
                md="6"
                v-if="showGender"
              >
                <v-select
                  v-model="subscriber.gender"
                  :items="genders"
                  item-text="text"
                  item-value="value"
                  label="Gender"
                  class="purple-input"
                  outlined
                  :rules="rules.gender"
                />
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-select
                  v-model="subscriber.duration_id"
                  :items="durations"
                  item-text="duration_text"
                  item-value="id"
                  :rules="rules.duration"
                  label="Duration"
                  class="purple-input"
                  outlined
                />
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="6"
              >
                <v-menu
                  v-model="menu2"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="subscriber.travel_date"
                      label="Activation Date"
                      locale="en-in"
                      :rules="rules.travelDate"
                      class="purple-input"
                      append-icon="mdi-calendar-range"
                      readonly
                      v-on="on"
                      outlined
                    />
                  </template>
                  <v-date-picker
                    v-model="subscriber.travel_date"
                    year-icon="mdi-calendar-blank"
                    prev-icon="mdi-skip-previous"
                    next-icon="mdi-skip-next"
                    :min="minDate"
                    @input="menu2 = false"
                  />
                </v-menu>
              </v-col>
              <v-col
                hidden
                cols="12"
                sm="6"
                md="6"
              >
                <v-menu
                  v-model="menu3"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="subscriber.start_date"
                      label="Start Date"
                      locale="en-in"
                      class="purple-input"
                      append-icon="mdi-calendar-range"
                      readonly
                      v-on="on"
                      outlined
                    />
                  </template>
                  <v-date-picker
                    v-model="subscriber.start_date"
                    year-icon="mdi-calendar-blank"
                    prev-icon="mdi-skip-previous"
                    next-icon="mdi-skip-next"
                    :min="minDate"
                    @input="menu3 = false"
                  />
                </v-menu>
              </v-col>
              <v-col
                cols="12"
                class="text-right">
                <v-btn
                  color="success"
                  class="mr-0"
                  :loading="loading"
                  :disabled="loading"
                  @click="submit">
                  Add Subscriber
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </base-material-card>
    </v-container>
  </v-app>
</template>
<script>

  import { VueTelInput } from 'vue-tel-input'
  import VValidationErrors from "../../components/base/VValidationErrors";

  // eslint-disable-next-line no-undef

  export default {
    name: 'CreateSubscriber',
    components: {
      VValidationErrors,
      VueTelInput,
    },
    data () {
      return {
        loading: false,
        subscriber: {
          first_name: '',
          last_name: '',
          email: null,
          date_of_birth: this.formatDate(new Date()),
          mobile: '',
          duration_id: '',
          travel_date: '',
          countryCode: null,
          start_date: '',
          gender: null,
          country_id: null,
          branch_id: null
        },
        countries: [],
        branches: [],
        genders: [
          {
            text: 'Male',
            value: 'male'
          },
          {
            text: 'Female',
            value: 'female'
          }
        ],
        validationErrors: {},
        rules: {
          firstName: [
            v => !!v || 'First Name field is required',
          ],
          lastName: [
            v => !!v || 'Last Name field is required',
          ],
          email: [
            v => !v || /.+@.+/.test(v) || 'Invalid Email address'
          ],
          mobileNumber: [
            v => !!v || 'Mobile field is required',
            // v => !v || /.+@.+/.test(v) || 'Invalid Email address'
          ],
          dateOfBirth: [
            v => !!v || 'Date of birth field is required',
          ],
          gender: [
            v => !!v || 'Gender field is required',
          ],
          duration: [
            v => !!v || 'Duration field is required',
          ],
          travelDate: [
            v => !!v || 'Activation Date field is required',
          ],
        },
        minDate: new Date().toISOString().slice(0, 10),
        durations: [],
        date: new Date().toISOString().substr(0, 10),
        menu: false,
        menu2: false,
        menu3: false,
        modal: false,
        partnerOptionalFields : [],
        showGender : false,
        showEmail : false,
        showFirstName : false,
        showLastName : false,
        showDateOfBirth : false,
        showCountryId : false,
        showBranchId : false
      }
    },
    computed: {
      maxDate() {
        const today = new Date();
        return this.formatDate(today);
      },
    },
    created () {
      this.getDurationList();
      this.getPartnerOptionalFields();
      this.getCountries();
      this.getBranches();
    },
    methods: {
      submit () {
        this.subscriber.start_date = this.subscriber.travel_date;
        if (!this.$refs.form.validate()) {
          return
        }
        this.loading = true;
        this.$authApi.post('/subscribers', this.subscriber)
          .then(res => {
            this.loading = false;
            this.flashMessage.success({
              title: 'User Subscribed  Successfully',
              message: `Subscriber created successfully`,
            })
            this.$router.push('/subscribers')
          }).catch(error => {
            if (error.response) {
              this.validationErrors = error.response.data.errors;
            }
            this.loading = false;
          })
      },
      countrySelected (val) {
        this.subscriber.countryCode = val.dialCode
      },
      getDurationList () {
        this.$authApi.get('durations/active').then(res => {
          this.durations = res.data.data
        }).catch(err => {
          console.log(err)
        })
      },
      getPartnerOptionalFields () {
        this.$authApi.get('partner-optional-fields').then(res => {
          this.partnerOptionalFields = res.data.data
          let _this = this
          this.partnerOptionalFields.forEach(function (value) {

            if (value.name == 'first_name') {
              _this.showFirstName = true
            }

            if (value.name == 'last_name') {
              _this.showLastName = true
            }
            if (value.name == 'gender') {
              _this.showGender = true
            }
            if (value.name == 'date_of_birth') {
              _this.showDateOfBirth = true
            }

            if (value.name == 'email') {
              _this.showEmail = true
            }

            if (value.name == 'country_id') {
              _this.showCountryId = true
            }

            if (value.name == 'branch_id') {
              _this.showBranchId = true
            }
          })

          if(_this.showDateOfBirth == false){
            this.subscriber.date_of_birth = '';
          }

        })
      },
      formatDate(date) {
        let month = `${date.getMonth() + 1}`;
        let day = `${date.getDate()}`;
        const year = date.getFullYear() - 13;

        if (month.length < 2) month = `0${month}`;
        if (day.length < 2) day = `0${day}`;

        return [year, month, day].join('-');
      },
      getCountries()
      {
        this.$authApi.get('countries').then(res => {
          this.countries = res.data.data;
        })
      },
      getBranches()
      {
        this.$authApi.get('branches').then(res => {
          this.branches = res.data.data.data;
          console.log(this.branches)
        })
      },
    },
  }
</script>

<style scoped>
  .vue-tel-input {
    height: 55px !important;
  }
</style>
